<template>
  <b-dropdown noCaret right variant="link">
    <div slot="button-content" class="px-2"><i class="fa fa-ellipsis-v"></i></div>
    <b-dropdown-item :to="{ name: 'TournamentHome', params: { lang: lang, console: console, tournamentSlug: slug } }">{{$t('home')}}</b-dropdown-item>
    <!-- <b-dropdown-item :to="{ name: 'TournamentBests', params: { lang: lang, console: console, tournamentSlug: slug } }">{{$t('theBest')}}</b-dropdown-item> -->
    <!-- <b-dropdown-item :to="{ name: 'TournamentRankinglayers', params: { lang: lang, console: console, tournamentSlug: slug } }">Ranking</b-dropdown-item> -->
    <!-- <b-dropdown-item :to="{ name: 'TournamentSignings', params: { lang: lang, console: console, tournamentSlug: slug } }">{{ $t('signings') }}</b-dropdown-item> -->
    <b-dropdown-item :to="{ name: 'TournamentRules', params: { lang: lang, console: console, tournamentSlug: slug } }">{{ $t('rules') }}</b-dropdown-item>
    <b-dropdown-item :to="{ name: 'TournamentHome', params: { lang: lang, console: console, tournamentSlug: slug } }">{{ $t('divisions') }}</b-dropdown-item>
    <b-dropdown-item :to="{ name: 'TournamentTeams', params: { lang: lang, console: console, tournamentSlug: slug } }">{{ $t('teams') }}</b-dropdown-item>
    <b-dropdown-item :to="{ name: 'TournamentTransfers', params: { lang: lang, console: console, tournamentSlug: slug } }">{{ $t('transfers') }}</b-dropdown-item>
    <!-- <b-dropdown-item :to="{ name: 'TournamentBests', params: { lang: lang, console: console, tournamentSlug: slug } }">{{ $t('theBest') }}</b-dropdown-item> -->
    <!-- <b-dropdown-item :to="{ name: 'TournamentBestGks', params: { lang: lang, console: console, tournamentSlug: slug } }">{{ $t('theBest') }} - Gk</b-dropdown-item> -->
    <!-- <b-dropdown-item :to="{ name: 'TournamentBestAssists', params: { lang: lang, console: console, tournamentSlug: slug } }">{{ $t('theBest') }} - {{ $t('assistances') }}</b-dropdown-item> -->
    <!-- <b-dropdown-item :to="{ name: 'TournamentBestScorers', params: { lang: lang, console: console, tournamentSlug: slug } }">{{ $t('theBest') }} - {{ $tc('scorer', 2) | toCapitalize }}</b-dropdown-item> -->
    <!-- <b-dropdown-item :to="{ name: 'TournamentBestMvps', params: { lang: lang, console: console, tournamentSlug: slug } }">{{ $t('theBest') }} - Mvp</b-dropdown-item> -->
    <b-dropdown-item :to="{ name: 'TournamentRankinglayers', params: { lang: lang, console: console, tournamentSlug: slug } }">{{ $t('players') }}</b-dropdown-item>
    <!-- <b-dropdown-item :to="{ name: 'TournamentRankingTeams', params: { lang: lang, console: console, tournamentSlug: slug } }">{{ $t('teams') }}</b-dropdown-item> -->
  </b-dropdown>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    slug: {
      required: true,
      type: String
    }
  },
  computed: {
    ...mapGetters([
      'lang',
      'console'
    ])
  }
}
</script>
